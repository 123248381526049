import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "\bflex flex-col absolute h-full w-full overflow-auto bg-[#eee]" }
const _hoisted_2 = { class: "flex justify-end p-[.2rem] bg-[#fff] mb-[.2rem]" }
const _hoisted_3 = {
  key: 1,
  class: "flex gap-x-[.2rem]"
}
const _hoisted_4 = { class: "pb-[1.34rem]" }
const _hoisted_5 = { key: 0 }

import { ref, reactive, onMounted, watch } from 'vue'
import { CheckboxGroup, Button, showConfirmDialog, Empty, showDialog } from 'vant'
import CartItem from './components/cartItem.vue'
import CartFooter from './components/cartFooter.vue'
import { addCartItemToWMApi, delCartItemApi, getCartApi } from '@/api/common'
import { useRoute } from 'vue-router'
import { getIntegrateUrl } from '../Pixi/components/utils'
import wx from 'weixin-js-sdk';
import { useI18n } from 'vue-i18n';

const miniProgramCartPath = '/ec_cart/index?productInstanceId=12799718114&vid=0'

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {},
  emits: [],
  setup(__props, { expose: __expose, emit: __emit }) {

const { t, locale } = useI18n();
const props = __props
const emits = __emit
const route = useRoute()
// 微影测试
// const miniProgramCartPath = '/ec_cart/index?productInstanceId=16576440374&vid=0'
// 微影正式
const appId = ref(localStorage.getItem('APPID'))

const hasEdit = ref(false)
const checkedItems = ref<any>({})
const cartItems = ref<CartItem[]>([])
const getCartItem = async () => {
  // let { id } = route.query
  const result = await getCartApi()

  cartItems.value = result.data.cart_items.map(t => ({
    quantity: t.quantity,
    itemName: '1111',
    stock: 10,
    sku_name: t.sku_name,
    id: t.id,
    sku_id: t.sku_id,
    item_id: t.item_id,
    effect_image: t.custom_design?.[0]?.effect_image?.url,
  }))
  let id = localStorage.getItem('PCA_ID')
  if (id) {
    
    // cartItems.value = result.data.map(t => ({
    //   ...t,
    //   effect_image: t.effect_image.split(',').map(m => getIntegrateUrl(m, 'tplv-z8ialgywaa-w-size:375'))
    // }))
  }
}

const toggleEdit = () => {
  hasEdit.value = !hasEdit.value
}
const onDelCartItem = () => {
  if (!checked.value.length) {
    return
  }
  showConfirmDialog({
    title: t('tip'),
    message: `${t('confirm_del')}${checked.value.length}${t('ge_item')}`,
  })
    .then(async () => {
      // on confirm
      await delCartItemApi(checked.value.join(','))
      getCartItem()
      toggleEdit()
    })
}
const checked = ref([])
const checkboxGroup = ref<any | null>(null);
const checkAll = () => {
  checkboxGroup.value?.toggleAll(true);
}
const toggleAll = (val: boolean) => {
  checkboxGroup.value?.toggleAll(val);
}

const submitDisabled = ref(false)
const addCartItemToWM = ({ goodsId, skuId, bizWid, cloudCustom, quantity }: any) => {
  let { app_id } = route.query
  return addCartItemToWMApi({
    appId: localStorage.getItem('APPID') || '',
    goodsId,
    skuId,
    quantity,
    bizWid,
    cloudCustom,
  })
}
const onSubmit = async () => {
  submitDisabled.value = true
  const bizWid = localStorage.getItem('WID') || ''
  const cloudCustom = JSON.stringify(checked.value.map((id) => cartItems.value.find(t => t.id === id)))
  try {
    const promiseList: Promise<any>[] = []
    checked.value.forEach((id) => {
      const checkedItem = cartItems.value.find(t => t.id === id)
      promiseList.push(
        addCartItemToWM({
          goodsId: String(checkedItem.item_id),
          skuId: String(checkedItem.sku_id),
          quantity: checkedItem.quantity,
          bizWid,
          cloudCustom,
        })
      )
    })
    await Promise.all(promiseList)

    appId.value
      ? wx.miniProgram.navigateTo({
        url: miniProgramCartPath,
      })
      : showDialog({
        title: t('tip'),
        message: t('not_found_app')
      })
    submitDisabled.value = false
  } catch(err) {
    showDialog({
      title: t('tip'),
      message: err?.error || t('add_cart_fail'),
    })
    submitDisabled.value = false
  }
}
const onItemChange = (id: number, { quantity }: { quantity?: number } = {}) => {
  const item = cartItems.value.find(t => t.id === id)
  if (!item) {
    return
  }
  if (typeof quantity === 'number') {
    item.quantity = quantity
  }
}
// const jsApiList = [];
onMounted(() => {
  appId.value && wx.config({
    debug: false,
    appId: appId.value,
    timestamp: Date.now(),
    nonceStr: 'nonceStr',
    signature: 'signature',
  })
  getCartItem()
})

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!hasEdit.value)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: "text-[#1989fa] text-[.28rem]",
            onClick: toggleEdit
          }, _toDisplayString(_unref(t)('edit')), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("p", {
              class: "text-[#1989fa] text-[.28rem]",
              onClick: onDelCartItem
            }, _toDisplayString(_unref(t)('delete')), 1),
            _createElementVNode("p", {
              class: "text-[#1989fa] text-[.28rem]",
              onClick: toggleEdit
            }, _toDisplayString(_unref(t)('cancel')), 1)
          ]))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_unref(CheckboxGroup), {
        modelValue: checked.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((checked).value = $event)),
        shape: "square",
        ref_key: "checkboxGroup",
        ref: checkboxGroup
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cartItems.value, (item) => {
            return (_openBlock(), _createBlock(CartItem, {
              checked: checkedItems.value[item.id],
              key: item.id,
              id: item.id,
              stock: item.quantity,
              effectImage: item.effect_image,
              itemName: item.item_name,
              skuName: item.sku_name,
              quantity: item.quantity,
              onChange: onItemChange
            }, null, 8, ["checked", "id", "stock", "effectImage", "itemName", "skuName", "quantity"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      (!cartItems.value?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_unref(Empty), {
              description: _unref(t)('not_item')
            }, null, 8, ["description"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(CartFooter, {
      disabled: submitDisabled.value,
      checkedNum: checked.value.length,
      total: cartItems.value.length,
      onToggleAll: toggleAll,
      onSubmit: onSubmit
    }, null, 8, ["disabled", "checkedNum", "total"])
  ]))
}
}

})