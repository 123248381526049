import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed bottom-0 left-0 w-full text-[.24rem] bg-[#fff]" }
const _hoisted_2 = { class: "px-[.2rem] flex justify-between py-[.24rem]" }
const _hoisted_3 = { class: "flex items-center gap-x-[.2rem]" }

import { ref, reactive, watch } from 'vue'
import { CheckboxGroup, Checkbox, Stepper, Button } from 'vant'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'cartFooter',
  props: {
  total: Number,
  checkedNum: Number,
  disabled: Boolean,
},
  emits: ['toggleAll', 'submit'],
  setup(__props, { expose: __expose, emit: __emit }) {

const { t, locale } = useI18n();
const props = __props
const emits = __emit
const router = useRouter()

const checked = ref(false)
const onChange = () => {
  // console.log(checked.value)
  emits('toggleAll', checked.value)
}

watch([() => props.total, () => props.checkedNum], ([total, checkedNum]) => {
  checked.value = total === checkedNum
})

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Checkbox), {
        "checked-color": "#ed3737",
        modelValue: checked.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((checked).value = $event)),
        onClick: onChange
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(t)('all_select')), 1)
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(Button), {
          class: "bg-[#ed3737] text-[#fff] border-0",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push('/pixi')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('design_again')), 1)
          ]),
          _: 1
        }),
        _createVNode(_unref(Button), {
          class: "bg-[#ed3737] text-[#fff] border-0 disabled:bg-[#999] disabled:text-[#fff]",
          disabled: __props.checkedNum === 0 || __props.disabled,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (emits('submit')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('add_to_cart')) + "（" + _toDisplayString(__props.checkedNum) + "）", 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ])
  ]))
}
}

})